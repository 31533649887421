/* eslint-disable  */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable  */

import React from 'react';
import { Modal } from '@material-ui/core';
import './LoaderStyles.scss';

const Loader = () => {
  //const classes = useStyles();
  return (
    <Modal open BackdropProps={{ style: { backgroundColor: 'rgba(255,255,255,0.8)' } }}>
      <div className="loaderOuter">
        <img src={require('../../assets/loader/loader.svg').default} alt="Loader" />
        <span>Loading...</span>
      </div>
    </Modal>
  );
};

export default Loader;
