import { AUTH_EVENTS } from '../constants/actionTypes';

export const initialState = {
  loading: false,
  data: null,
  error: null,
};

const authReducer = (state, { type, payload }) => {
  switch (type) {
    case AUTH_EVENTS.LOGIN_REQUEST:
      return { ...state, loading: true };

    case AUTH_EVENTS.LOGIN_SUCCESS:
      return { ...state, loading: false, data: payload };

    case AUTH_EVENTS.LOGIN_FAIL:
      return { ...state, loading: false, error: payload };

    case AUTH_EVENTS.LOGIN_RESET:
      return initialState;

    default:
      return state;
  }
};

export default authReducer;
