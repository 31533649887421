import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import { ContextDevTool } from "react-context-devtool";
import networkReducer, { initialState } from "../reducers/networkReducer";

export const NetworkContext = createContext();

const NetworkProvider = ({ children }) => {
  const useNetworkState = useReducer(networkReducer, initialState);

  return (
    <NetworkContext.Provider value={[...useNetworkState]}>
      <ContextDevTool
        context={NetworkContext}
        id="NetworkContext"
        displayName="Network Context"
      />
      {children}
    </NetworkContext.Provider>
  );
};

NetworkProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NetworkProvider;
