import React from "react";
// import PropTypes from 'prop-types';

import { Grid } from "@material-ui/core";
import useStyles from "./NoFound.style";
import Oops from "../../assets/404/oops.png";
import Image404 from "../../assets/404/404.png";
import Disconnect from "../../assets/404/disconnect.png";

const NoFound = ({ fromSalesforce = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="center">
            <Grid item xs={6} md={2}>
              <img className={classes.oops} src={Oops} alt="OOPS" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <p className={classes.msg}>
                {fromSalesforce
                  ? "No client-level user for this program event"
                  : "We can’t seem to find the page you’re looking for."}
              </p>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <Grid item xs={12}>
              <img
                className={classes.disconnect}
                src={Disconnect}
                alt="Disconnect"
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                  <p className={classes.labeltext}>Error code:</p>
                </Grid>
              </Grid>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                  <img
                    className={classes.fournotfour}
                    src={Image404}
                    alt="404"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

NoFound.propTypes = {};

export default NoFound;
