import { useContext, createContext } from 'react';

export default class GlobalData {
  practiceClassHasPrepped = undefined;

  setPracticeClassHasPrepped(val) {
    this.practiceClassHasPrepped = val;
  }
}

const gdContext = createContext(new GlobalData());

export const GlobalDataContext = gdContext;

export const useGlobalData = () => useContext(gdContext)