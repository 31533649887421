/* eslint-disable */

import React, { lazy } from "react";

const RankingDetails = lazy(() => import("../screens/Main/RankingDetails"));

// const UserProfile = lazy(() => import('../screens/Main/UserProfile'));
const BadgesDetails = lazy(() => import("../screens/Main/BadgesDetails"));
const NewProject = lazy(() =>
  import("../screens/Main/v1/NewProject/NewProject")
);
const LandingPage = lazy(() => import("../screens/Main/LandingPage"));
const LandingPagev1 = lazy(() => import("../screens/Main/v1/LandingPage"));
const LandingScreen = lazy(() =>
  import("../screens/Main/v1/LandingPage/LandingScreen")
);
const LicenseManagement = lazy(() =>
  import("../screens/Main/v1/LicenseManagement/MainPage")
);
const Message = lazy(() => import("../screens/Main/v1/Message/Message"));
const Classes = lazy(() => import("../screens/Main/v1/LandingPage/Classes"));
const MyAccount = lazy(() => import("../screens/Main/v1/MyAccount/MyAccount"));
const LoginViaSalesforce = lazy(() =>
  import("../screens/Auth/LoginViaSalesforce")
);
const LicenseManagementViaProjectId = lazy(() =>
  import("../screens/Main/v1/LicenseManagement/LicenseManagementViaProjectId")
);

const LandingPage2 = lazy(() =>
  import("../2.0/LandingPage/LandingPage")
);

const HowToPlay = lazy(() =>
  import("../2.0/HowToPlay/HowToPlay")
);

const HowToPlaySeason = lazy(() =>
  import("../2.0/HowToPlay/HowToPlaySeason/HowToPlaySeason")
);

const HowToPlayEpisode = lazy(() =>
  import("../2.0/HowToPlay/HowToPlayEpisode/HowToPlayEpisode")
);

const AccountManagement = lazy(() =>
  import("../2.0/AccountManagement/AccountManagement")
);

// const SeasonChildRoutes = [
//   {
//     name: 'playSeason',
//     path: 'seasons/play',
//     element: <PlayEpisode />,
//   },
//   // {
//   //   name: 'season',
//   //   path: 'season',
//   //   element: <Season />,
//   //   // children:
//   // },

// ];

export const ChildRoutes = [
  {
    name: "dashboard",
    path: "school/:schoolId",
    element: <LandingPagev1 />,
    // children: SeasonChildRoutes
  },

  // {
  //   name: 'dashboard',
  //   path: 'dashboard/:schoolId',
  //   element: <Dashboard />,
  //   // children: SeasonChildRoutes
  // },
  {
    name: "rankingdetails",
    // path: 'dashboard/:schoolId/rankingdetails/:id',
    path: "school/:schoolId/rankingdetails/:id",
    element: <RankingDetails />,
  },
  // {
  //   name: 'userProfile',
  //   path: 'userprofile',
  //   element: <UserProfile />,
  // },
  // {
  //   name: 'seasonEpisode',
  //   path: 'season/episode',
  //   element: <EpisodePage />,
  // },
  {
    name: "badgedetails",
    path: "school/:schoolId/badgedetails/:id",
    element: <BadgesDetails />,
  },
  {
    name: "newProject",
    path: "school/:schoolId/newProject/:urlprojectId",
    element: <NewProject />,
  },
  {
    name: "landingPage",
    path: "landing-page-old",
    element: <LandingPage />,
  },
  // {
  //   name: "landingPage",
  //   path: "landing-page",
  //   element: <LandingScreen />,
  // },
  {
    name: "landingPage",
    path: "landing-page",
    element: <LandingPage2 />,
  },
  {
    name: "howToPlay",
    path: "how-to-play",
    element: <HowToPlay />,
  },
  {
    name: "howToPlay",
    path: "how-to-play/:season",
    element: <HowToPlaySeason />,
  },
  {
    name: "howToPlay",
    path: "how-to-play/:season/:episode",
    element: <HowToPlayEpisode />,
  },
  {
    name: "License Management",
    path: "license-management",
    element: <LicenseManagement />,
  },
  {
    name: "License Management",
    path: "license-management/:programEventId",
    element: <LicenseManagement />,
  },
  {
    name: "License Management",
    path: "licenseManagementViaProjectId/:projectId",
    element: <LicenseManagementViaProjectId />,
  },
  {
    name: "Account Management",
    path: "account-management",
    element: <AccountManagement />,
  },
  {
    name: "LandingScreen",
    path: "landing-screen",
    element: <LandingPagev1 />,
  },
  {
    name: "Classes",
    path: "classes/:classId",
    element: <Classes />,
  },
  {
    name: "Message",
    path: "message",
    element: <Message />,
  },
  {
    name: "MyAccount",
    path: "my-account",
    element: <MyAccount />,
  },
  {
    name: "Login Via Salesforce",
    path: "loginViaSalesforce",
    element: <LoginViaSalesforce />,
  },
];

export default ChildRoutes;
