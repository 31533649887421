import React, { useEffect, useCallback, useContext } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Cookies from "js-cookie";
import ajax from "./ajaxHelper";
import { NetworkContext } from "../contexts/networkContext";
import { NETWORK_EVENTS } from "../constants/actionTypes";
import Loader from "../components/Loader/Loader";
import { useNavigate, useLocation } from "react-router-dom";

/**
 * AjaxIterceptor created for global loader and global error handler
 *
 * @returns
 */
const AjaxInterceptors = () => {
  const [state, dispatch] = useContext(NetworkContext);

  const navigate = useNavigate();
  const locationRef = useLocation();
  let key = "iFrasCwdJh";
  const onRequest = useCallback(
    (request) => {
      const user = Cookies.get("user");
      let companyDetails = {};
      if (user) {
        const parsedUser = JSON.parse(atob(user));

        if (parsedUser.companyId) {
          companyDetails = {
            companyId: parsedUser.companyId,
          };
        }
      }

      const token = Cookies.get("token");
      let authorization = {};
      if (token) {
        authorization = {
          Authorization: `Bearer ${token}`,
        };
      }

      const updatedRequest = {
        ...request,
        headers: {
          ...request.headers,
          ...authorization,

          ...companyDetails,
        },
      };
      dispatch({
        type: NETWORK_EVENTS.NETWORK_CALL_REQUEST,
        payload: request.networkParams || {},
      });
      return updatedRequest;
    },
    [dispatch]
  );

  const onRequestError = useCallback(
    (error) => {
      dispatch({
        type: NETWORK_EVENTS.NETWORK_CALL_FAIL,
        payload: error,
      });
      return Promise.reject(error);
    },
    [dispatch]
  );

  const onResponse = useCallback(
    (response) => {
      if (response.data.fieldMessage) {
        response.data.message = "";
      }

      if (!response.data.status) {
        dispatch({
          type: NETWORK_EVENTS.NETWORK_CALL_FAIL,
          payload: new Error(
            response.data.message ? response.data.message : null
          ),
        });

        return Promise.reject(
          new Error(response.data.message ? response.data.message : null)
        );
      }

      dispatch({
        type: NETWORK_EVENTS.NETWORK_CALL_SUCCESS,
        payload: response.data,
      });
      return response.data;
    },
    [dispatch]
  );

  const onResponseError = useCallback(
    (error) => {
      if (error.response) {
        if (error.response.status == 500) {
          error.message = "Something went wrong. Please try later.";
        }
        if (error.response.status == 401) {
          error.message = "Something went wrong. Please try to login again.";
          navigate("../../login");
        }
      }
      if (error.response && error.response.status == 422) {
        if (error.request && error.request.response) {
          let requestErrorResponse = JSON.parse(error.request.response);
          if (requestErrorResponse && requestErrorResponse.message) {
            error.message = requestErrorResponse.message;
          }
        }
      }
      if (error.response?.status == 501) {
        if (error.response.data.forceLogout) {
          error.message = error.response.data.message;
          setTimeout(() => navigate("../../login"), 4000);
        }
        if (error.response.data.duplicate) {
          error.message = error.response.data.message;
        }
      }
      dispatch({
        type: NETWORK_EVENTS.NETWORK_CALL_FAIL,
        payload: error,
      });

      return Promise.reject(error);
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    dispatch({ type: NETWORK_EVENTS.NETWORK_CALL_RESET });
  }, [dispatch]);

  useEffect(() => {
    const requestInterceptor = ajax.interceptors.request.use(
      onRequest,
      onRequestError
    );
    const responseInterceptor = ajax.interceptors.response.use(
      onResponse,
      onResponseError
    );

    return () => {
      ajax.interceptors.request.eject(requestInterceptor);
      ajax.interceptors.response.eject(responseInterceptor);
    };
  }, [onRequest, onRequestError, onResponse, onResponseError]);

  return (
    <>
      {state.loading && state.showLoader && <Loader />}
      {!!state.error && state.error.message !== "null" && (
        <Snackbar open={!!state.error} onClose={handleClose}>
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity="error"
          >
            {state.error.message}
          </Alert>
        </Snackbar>
      )}
      {/* <When condition={!!state.error && state.count === 0 && state.error.message !== 'null'}> */}
      {!!state.success && state.count === 0 && (
        <Snackbar
          open={!!state.success}
          autoHideDuration={6000} //{(state.data && state.data.data) || 6000}
          onClose={handleClose}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleClose} //{state.data && state.data.data ? false : handleClose}
            severity="success"
          >
            {state.success}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AjaxInterceptors;
