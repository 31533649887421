import React, { createContext, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import { ContextDevTool } from "react-context-devtool";
import licenseManagementReducer, {
  initialState,
} from "../reducers/licenseManagementReducer";

export const LicenseManagementContext = createContext();

export const useLicenseManagementContext = () =>
  useContext(LicenseManagementContext);
const LicenseManagementProvider = ({ children }) => {
  const useLicenseManagementState = useReducer(
    licenseManagementReducer,
    initialState
  );

  return (
    <LicenseManagementContext.Provider value={[...useLicenseManagementState]}>
      <ContextDevTool
        context={LicenseManagementContext}
        id="LicenseManagementContext"
        displayName="LicenseManagement Context"
      />
      {children}
    </LicenseManagementContext.Provider>
  );
};

LicenseManagementProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LicenseManagementProvider;
