/* eslint-disable*/
import { createTheme } from "@material-ui/core/styles";
import { size } from "lodash";

const theme = createTheme({
  // Color style
  palette: {
    primary: {
      main: "#F88700",
      contrastText: "#fff",
    },
    secondary: {
      main: "#565656",
      contrastText: "#a29c99",
    },
    tertiary: {
      main: "#61B3CD",
      contrastText: "#fff",
    },
  },
  // Fonts style
  typography: {
    fontFamily: ["Nunito Sans", "sans-serif"].join(","),
    h1: {
      fontSize: 24,
      fontWeight: 400,
      color: "#fff",
      wordBreak: "break-word",
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
      color: "#565656",
      wordBreak: "break-word",
    },
    h3: {
      fontSize: 20,
      fontWeight: 400,
      color: "#565656",
      wordBreak: "break-word",
    },
    h4: {
      fontSize: 20,
      fontWeight: 400,
      color: "#4B403A",
      wordBreak: "break-word",
    },
    h5: {
      fontSize: 16,
      fontWeight: 400,
      color: "#262626",
      wordBreak: "break-word",
    },
    h6: {
      fontSize: 14,
      fontWeight: 400,
      color: "#565656",
      wordBreak: "break-word",
    },
    body1: {
      fontSize: 12,
      fontWeight: 700,
      color: "#565656",
      wordBreak: "break-word",
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      color: "#565656",
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
      color: "#7E7E7E",
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      color: "#7E7E7E",
    },
  },
  // Button style
  // forms style
  // Overrides style - button, forms

  overrides: {
    breakpoints: {
      values: {
        xs: 0,
        sm: 767,
        md: 1000,
        lg: 1024,
        xl: 1920,
      },
    },

    MuiPopover: {
      backgroundColor: "transparent",
      borderRadius: "8px",
    },

    MuiListItemIcon: {
      root: {
        minWidth: "auto",
        color: "#F88700",
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: "12px",
        color: "#7E7E7E",
      },
    },
    MuiPaper: {
      root: {
        paddingLeft: "0px",
        paddingRight: "0px",
        color: "#4B403A",
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
        margin: "0",
      },
    },
    MuiListItem: {
      root: {
        flexDirection: "column",
      },
      gutters: {
        paddingLeft: "12px",
        paddingRight: "12px",
      },
      button: {
        "&:hover": {
          "& .MuiListItemIcon-root": {
            color: "#F88700",
          },
          "& .MuiListItemText-primary": {
            color: "#F88700",
          },
        },
        "&:active": {
          background: "#F88700",
          color: "#fff",
          "& .MuiListItemIcon-root": {
            color: "#fff",
          },
          "& .MuiListItemText-primary": {
            color: "#fff",
          },
        },
      },
    },

    MuiDrawer: {
      paperAnchorDockedLeft: {
        boxShadow: "3px 0 5px 0 rgba(0,0,0,0.1)",
      },
    },

    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#fff",
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
        fontSize: 14,
        fontWeight: 700,
        textTransform: "normal",
        color: "#FFFFFF",
        padding: "8px 30px",
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      containedPrimary: {
        background: "linear-gradient(180deg, #F88700 9.3%, #FFDFB8 225.58%)",
      },
      outlinedSecondary: {
        color: "#616569",
        border: "1px solid #92979d",
        padding: "7px 16px",
        fontWeight: "400",
        "&:hover": {
          color: "#616569",
          border: "1px solid #92979d",
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 8,
        padding: "8px 12px",
        color: "#FFFFFF",
        fontSize: 14,
        fontWeight: 400,
      },
      contained: {
        color: "#FFFFFF",
        backgroundColor: "rgba(162, 156, 153, 1)",
        minWidth: "120px",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
        lineHeight: 1.5,
        "&.$Mui-disabled": {
          color: "#000",
        },
        "&.Mui-focused": {
          color: "#565656",
        },
      },
      shrink: {
        fontSize: 18,
        color: "#565656",
        fontWeight: 400,
      },
      formControl: {
        position: "relative",
      },
    },

    MuiOutlinedInput: {
      root: {
        border: "1px solid #e1e4e8",
        borderRadius: 8,
        backgroundColor: "rgba(255,255,255,0.8)",
        fontSize: "12px",
        "&:hover $notchedOutline": {
          borderColor: "transparent",
        },
        "&$focused $notchedOutline": {
          borderColor: "transparent",
        },
      },
      input: {
        padding: "10px 12px",
      },
      inputMultiline: {
        borderRadius: 15,
        padding: 15,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
      },
      multiline: {
        padding: 0,
      },
      notchedOutline: {
        borderColor: "transparent",
      },
    },

    /* MuiAutocomplete: {
      option: {
        "&:hover": {
          backgroundColor: "#ffffff",
        },
        "&$checked": {
          backgroundColor: "#4B403A",
        },
      },
    }, */

    MuiInputBase: {
      root: {
        fontSize: 16,
        fontWeight: "400",
        width: "100%",
      },
      inputMarginDense: {
        paddingBottom: 8,
        paddingTop: 8,
      },
      input: {
        color: "#616569",
      },
      multiline: {
        fontSize: 16,
        color: "#4B403A",
        backgroundColor: "#fff",
        borderRadius: 15,
        fontWeight: 400,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
        fontWeight: 400,
        color: "#616569",
      },
    },

    MuiSelect: {
      icon: {
        color: "#92979d",
        right: "5px",
        top: "calc(50% - 12px)",
      },
      outlined: {
        borderRadius: 0,
      },
      select: {
        border: "2px solid #eaeaeb",
        borderRadius: "5px",
        borderRadius: 5,
        padding: "9px 10px",
        border: "none",
        boxShadow: "none",
        "&:focus": {
          borderRadius: 5,
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: "#61B3CD",
      },
    },
    MuiIconButton: {
      root: {
        color: "#92979d",
        padding: "12px",
      },
      edgeStart: {
        "@media (max-width:1279px)": {
          marginLeft: 0,
        },
      },
    },

    MuiAvatar: {
      img: {
        width: "auto",
        height: "auto",
      },
    },

    // Right Panel
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: "#4B403A",
        color: "#ffffff",
      },
    },

    MuiExpansionPanelDetails: {
      root: {
        padding: "10px 0px 10px 0px",
      },
    },

    // Datepicker
    MuiPickersBasePicker: {
      pickerView: {
        width: "auto",
        height: "auto",
        maxWidth: "auto",
        minWidth: "auto",
        minHeight: "auto",
        padding: "10px 20px 10px 20px",
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        marginTop: "15px",
        marginBottom: "30px",
      },
    },

    // Chip

    MuiChip: {
      root: {
        boxShadow: "0px 0 5px rgba(0,0,0,0.3)",
      },
      sizeSmall: {
        fontSize: "16px",
      },
    },

    // Overrides style - modal dialog
    MuiDialog: {
      paper: {
        backgroundColor: "#fff",
      },
    },

    MuiDialogContent: {
      root: {
        overflow: "auto",
        paddingTop: "0px",
        paddingBottom: "16px",
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },

    MuiDialogActions: {
      spacing: {
        paddingTop: "16px",
        paddingBottom: "30px",
        paddingLeft: "24px",
        paddingRight: "24px",
        justifyContent: "flex-end",
      },
    },

    // Chat

    // Overrides style - material ui data table

    MuiTableCell: {
      root: {
        borderBottom: "1px solid #f0f2f3",
        borderRight: "1px solid #f0f2f3",
        wordBreak: "break-word",
        padding: "12px",
        "&:last-child": {
          borderRight: "none",
          width: "96px!important",
          textAlign: "center",
        },
      },

      head: {
        padding: "18px 10px",
        backgroundColor: "#e1e4e8 !important",
        color: "#616569",
        fontWeight: "400",
        fontSize: 14,
        whiteSpace: "nowrap",
        borderBottom: "1px solid #616569",
      },

      body: {
        color: "#000",
      },
    },

    MuiTableRow: {
      root: {
        backgroundColor: "#FFFFFF",
        "&:hover": {
          backgroundColor: "#F1F1F1",
        },
      },
      footer: {
        backgroundColor: "#f3efea",
        "&:hover": {
          backgroundColor: "#f3efea",
        },
      },
    },

    MuiTablePagination: {
      select: {
        backgroundColor: "transparent",
        border: "0px solid #c4c4c4",
        boxShadow: "0px 0px 0px rgba(162,156,153,0.7)",
        lineHeight: "20px",
      },

      root: {
        "&:last-child": {
          width: "100%!important",
        },
      },
    },

    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
      },
      tooltipPlacementTop: {
        margin: "10px 0 !important",
      },
    },

    MuiToolbar: {
      gutters: {
        paddingLeft: "18px !important",
        paddingRight: "18px !important",
      },
    },

    MuiTabs: {
      indicator: {
        height: "3px",
        backgroundColor: "#F88700",
      },
    },
    MuiLink: {
      root: {
        color: "#0F75BB",
        fontSize: "14px",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "12px",
        marginRight: "12px",
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: "400",
        alignItems: "flex-start",
      },
    },
  },
});

export default theme;
