/* eslint-disable */
import React, { Suspense } from "react";
import { HashRouter } from "react-router-dom";
// import CssBaseline from '@material-ui/core/CssBaseline';
import NetworkProvider from "./contexts/networkContext";
import LicenseManagementProvider from "./contexts/licenseManagementContext";
import GlobalData, { GlobalDataContext } from './contexts/globalDataContext'
// import TaskProvider from './contexts/taskContext';

import AjaxInterceptors from "./utils/AjaxInterceptors";
import AppRoute from "./routes/index";
import Loader from "./components/Loader/Loader";
import LoginProvider from "./contexts/loginContext";
import StatusProvider from "./contexts/StatusContext";
import Cookies from "js-cookie";

// import ScrollToTop from './ScrollToTop';
//import { SnackbarProvider } from 'notistack';
// import { messaging } from './init-fcm';

function App() {
  /*React.useEffect(()=> {

  messaging.requestPermission()
    .then(async function() {
      const token = await messaging.getToken();
      console.log(token);
    })
    .catch(function(err) {
      console.log("Unable to get permission to notify.", err);
    });
navigator.serviceWorker.addEventListener("message", (message) => console.log(message));

}, [])*/

  /**
   * set the email for gist support to know the user email id
   */
  document.addEventListener("gistChatReady", function () {
    if (window.location.href.includes("/license-management/notFound")) {
      return;
    } else if (
      Cookies.get("Name") &&
      Cookies.get("email") &&
      // !window.location.href.endsWith("login") &&
      // !window.location.href.endsWith(".com") &&
      // !window.location.href.endsWith("/#/") &&
      !window.location.href.endsWith("password")
    ) {
      const name = Cookies.get("Name");
      let email = Cookies.get("email");
      email = email?.replace(/%40/g, "@");
      const newName = name?.replace(/%20/g, " ");
      const fullName = newName?.split(" ");

      gist.identify({
        email: email,
        name: fullName[0] + " " + fullName[1],
      });
    } else if (!Cookies.get("Name") && !Cookies.get("email")) {
      gist.chat("show");
    } else {
      gist.chat("hide");
    }
  });

  if (
    // window.location.href.endsWith("login") ||
    // window.location.href.endsWith(".com") ||
    // window.location.href.endsWith("/#/") ||
    window.location.href.endsWith("password")
  ) {
    if (gist && gist.chat) gist.chat("hide");
  }

  const globalData = new GlobalData();

  return (
    // <SnackbarProvider maxSnack={3}>
    <HashRouter>
      {/* <ScrollToTop />
      <CssBaseline /> */}
      <Suspense fallback={<Loader />}>
        <LoginProvider>
          <NetworkProvider>
            <LicenseManagementProvider>
              <GlobalDataContext.Provider value={globalData}>
                <>
                  <AjaxInterceptors />
                  <AppRoute />
                </>
              </GlobalDataContext.Provider>
            </LicenseManagementProvider>
          </NetworkProvider>
        </LoginProvider>
      </Suspense>
    </HashRouter>
    // {/* </SnackbarProvider> */}
  );
}

export default App;
