/* eslint-disable  */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable  */

import React, { useState, useEffect } from 'react';
import { Modal } from '@material-ui/core';
import ajax from '../../utils/ajaxHelper';
// import { showErrIfTouched } from "../../utils";
import Cookies from "js-cookie";
import {
  Box,
  // Button,
  // CircularProgress,
  // Grid,
  // FormControl,
  // InputLabel,
  // TextField,
} from "@material-ui/core";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
//Formik
// import { FastField, Formik } from "formik";
// import { object, string } from "yup";
import './CleverLoader.scss';

const CleverLoader = ({
  cleverCode
}) => {

  const [showCleverUserIdAssocationForm, setShowCleverUserIdAssocationForm] = useState(false)
  const [showNoMappedCleverEmail, setShowNoMappedCleverEmail] = useState(false)
  
  useEffect(() => {
    const fn = async () => {
      if (cleverCode && !showCleverUserIdAssocationForm) {
        const postBody = {
          "code": cleverCode,
        }

        await ajax.post(`/api/clevertoken`, postBody);

        const userId = Cookies.get("userId");
        const cleverUserId = Cookies.get("cleverUserId");

        if (cleverUserId && !userId) {
          setShowCleverUserIdAssocationForm(true)
        } else {
          const urlBeforeParams = window.location.href.split('?')[0];
          window.location = `${urlBeforeParams}#/landing-page`;
        }
      }
    }
    fn();
  }, [cleverCode, showCleverUserIdAssocationForm])

  useEffect(() => {
    const fn = async () => {
      if (cleverCode && showCleverUserIdAssocationForm) {
        const cleverUserId = Cookies.get("cleverUserId");
        const cleverAccessToken = Cookies.get("clever_access_token");

        let status;
        try {
          const postBody = { cleverUserId, cleverAccessToken };
          const response = await ajax.post(`/api/linkAccountToClever`, postBody) || {};

          const { status: responseStatus } = response;
          status = responseStatus;
        } catch (e){
          status = false;
        }

        if (status === true) {
          setShowCleverUserIdAssocationForm(false)
        } else {
          setShowNoMappedCleverEmail(true)
        }
      }
    }
    fn();
  }, [cleverCode, showCleverUserIdAssocationForm])

  // const createSchema = object().shape({
  //   email: string().required("Please enter a class name").trim(),
  // });

  // const getCleverUserIdAssocationForm = () => {
  //   return (
  //     <div style={{ width: '80%' }}>
  //       <Formik
  //         initialValues={{
  //           email: "",
  //         }}
  //         validationSchema={createSchema}
  //         onSubmit={async (values, formikAttributes) => {
  //           const { email } = values;
  //           const cleverUserId = Cookies.get("cleverUserId");

  //           const postBody = { cleverUserId, email };
  //           const response = await ajax.post(`/api/linkAccountToClever`, postBody) || {};
            
  //           const { setSubmitting } = formikAttributes;
  //           setSubmitting(false);

  //           const { status } = response;
  //           if (status === true) {
  //             setShowCleverUserIdAssocationForm(false)
  //           }
  //         }}
  //       >
  //       {({
  //         errors,
  //         isSubmitting,
  //         isValid,
  //         dirty,
  //         touched,
  //         handleSubmit,
  //       }) => {
  //         return (
  //           <>
  //             <div style={{ padding: '24px' }}>
  //             <Box display="flex">
  //               <Typography variant="h3">Link Clever to your ProSolve Account</Typography>
  //             </Box>
  //             <Box>
  //               <Typography variant="h6">
  //                 We were unable to find a ProSolve account linked to Clever so please provide the email address associated with your ProSolve account to create this link.
  //               </Typography>
  //             </Box>
  //             </div>
  //             <DialogContent>
  //               <Grid container spacing={2}>
  //                 <Grid item xs={12}>
  //                   <FormControl fullWidth>
  //                     <InputLabel shrink htmlFor="class-name">
  //                       Email*
  //                     </InputLabel>
  //                     <FastField
  //                       as={TextField}
  //                       variant="outlined"
  //                       id="email"
  //                       name="email"

  //                       placeholder="Enter Email"
  //                       error={
  //                         !!showErrIfTouched("email", errors, touched)
  //                       }
  //                       helperText={showErrIfTouched(
  //                         "email",
  //                         errors,
  //                         touched
  //                       )}
  //                       fullWidth
  //                     />
  //                   </FormControl>
  //                 </Grid>
  //                 </Grid>
  //             </DialogContent>
  //             <div style={{ display: 'flex', justifyContent: 'center' }}>
  //             <DialogActions align="right">
  //               <Button
  //                 variant="contained"
  //                 color="primary"
  //                 disabled={
  //                   isSubmitting || !dirty || !isValid
  //                 }
  //                 type="submit"
  //                 onClick={handleSubmit}
  //               >
  //                 Link Account To Clever
  //                 {isSubmitting && (
  //                   <CircularProgress size={24} />
  //                 )}
  //               </Button>
  //             </DialogActions>
  //             </div>
  //           </>
  //           );
  //         }}
  //       </Formik>
  //     </div>
  //   )
  // }

  const getAutoPerformUserIdAssocation = () => {
    return showNoMappedCleverEmail 
    ? 
    (
      <div style={{ padding: '24px' }}>
        <Box style={{ marginBottom: '10px'}} display="flex">
          <Typography variant="h3">Unable to link your Clever account to a ProSolve account.</Typography>
        </Box>
        <Box>
          <Typography variant="h6">
            We were unable to find an ProSolve account email which matched the email address associated with your Clever account. Please contact your adminstrator to get this resolved.
          </Typography>
        </Box>
      </div>
    )
    :
    (
      <div style={{ padding: '24px' }}>
        <Box display="flex">
          <Typography variant="h3">Setting up initial Clever Account Link...</Typography>
        </Box>
      </div>
    )
  }

  return (
    <Modal open BackdropProps={{ style: { backgroundColor: 'rgba(255,255,255,0.8)' } }}>
      <div className="loaderOuter">
        { showCleverUserIdAssocationForm ?
          getAutoPerformUserIdAssocation()
          :
          <>
          <img src={require('../../assets/loader/loader.svg').default} alt="Loader" />
          <span>Loading...</span>
          </>
        }
      </div>
    </Modal>
  );
};

export default CleverLoader;
