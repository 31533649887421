/* eslint-disable  */
import axios from "axios";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const host = window.location.host;
const domain = 'questboxes.com';
const subDomain = 'portal';
const apiSubDomain = 'api';

let baseURL;
if (host.indexOf('localhost') > -1) {
  baseURL = `http://localhost:3003/`;
} else {
  // detect env
  const parts = host.split('.');
  // Look backward to always get part prior to 'prosolve.com'
  const envPart = parts[parts.length - 3];
  const env = envPart !== subDomain ? `.${envPart}` : '';
  baseURL = `https://${apiSubDomain}.${subDomain}${env}.${domain}/`;
}

const instance = axios.create({
  baseURL,
  /// cancelToken: source.token,
  withCredentials: true,
});

export const abortAllRequest = () => {
  source.cancel(); ///Operation canceled by the user.
};

export default instance;
