/* eslint-disable  */
import { LICENSE_MANAGEMENT_EVENTS } from "../constants/actionTypes";

export const initialState = {
  loading: false,
  errors: false,
  success: false,
  count: 0,
  showLoader: true,
  data: null,
};

export default (state, { type, payload }) => {
  switch (type) {
    case LICENSE_MANAGEMENT_EVENTS.LICENSE_MANAGEMENT_CALL_REQUEST:
      return { ...state, loading: true, count: state.count + 1, ...payload };

    case LICENSE_MANAGEMENT_EVENTS.LICENSE_MANAGEMENT_CALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: payload.message,
        data: payload,
        count: state.count - 1,
      };
    case LICENSE_MANAGEMENT_EVENTS.LICENSE_MANAGEMENT_CALL_SET:
      return {
        ...state,
        loading: false,
        error: false,
        success: payload.message,
        data: payload,
      };

    case LICENSE_MANAGEMENT_EVENTS.LICENSE_MANAGEMENT_CALL_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload,
        count: state.count - 1,
      };

    case LICENSE_MANAGEMENT_EVENTS.LICENSE_MANAGEMENT_CALL_RESET:
      return initialState;

    default:
      return state;
  }
};
